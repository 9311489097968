import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const Blog = ({ data, location }) => {

  let items = []

  for (let i=0; i<9; i++) {
    let delay = 100;
    if (i >= 6 ) {
      delay = delay * (i-6)
    } else if (i >= 3) {
      delay = delay * (i-3)
    } else {
      delay = delay * i
    }
    items.push(
      <div className="col-md-4">
        <Link to={'/blog-detail'} className="blogList" data-sal="slide-up" data-sal-delay={delay}>
          <div className="blogList-img">
            <img src={require('../../static/images/blog1.jpg')} alt="Blog Desc"/>
            <span>Design</span>
          </div>
          <div className="blogList-desc">
            <h3>10 tips for drawing with coloured pencils</h3>
            <div>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua Ut enim...
            </div>
          </div>
        </Link>
      </div>
    )
  }

  let blogList = (
    <section id="blog-page" className="module">
      <div className="container">
        <div className="row">

          <div className="module-header" data-sal="slide-up">
            <h2 className="module-title"> Blog </h2>
            <div className="module-line"></div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <ul id="filter">
              <li className="active" data-sal="slide-right">All</li>
              <li data-group="design" data-sal="slide-right" data-sal-delay="100">Design</li>
              <li data-group="travel" data-sal="slide-right" data-sal-delay="200">Travel</li>
              <li data-group="passion" data-sal="slide-right" data-sal-delay="300">Passion</li>
            </ul>
          </div>

        </div>

        <div className="row">
          {
            items
          }
        </div>

        <div className="row">

          <div className="col-sm-12">

            <ul id="filter">
              <li className="active" data-sal="slide-right">1</li>
              <li data-sal="slide-right" data-sal-delay="100">2</li>
              <li data-sal="slide-right" data-sal-delay="200">3</li>
              <li data-sal="slide-right" data-sal-delay="300">4</li>
            </ul>

          </div>

        </div>
      </div>

    </section>
  )

  return (
    <Layout location={location}>
      <SEO title="Blog" />
      {blogList}
    </Layout>
  )
}

export default Blog